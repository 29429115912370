<template>
    <div class="main">
        <div class="top-bar">
            <div class="top-bar-right">
                <div class="toplogo">
                    <img src="../assets/images/logo.png"><i>叮叮家校互动</i>
                </div>
                <div class="top-bar-right-action">
                    <!--<div class="icon-bell"></div>-->

                    <el-dropdown class="drop-down-personal" @command="handleCommand">
                        <img src="../assets/images/头像.png" class="my-icon" />
                        <!--{{ nickName }}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="1">修改密码</el-dropdown-item>
                            <el-dropdown-item command="2">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <div class="main-block">
            <div class="nav-main">
                <el-menu :default-active="navCurrent" :router="true" active-text-color="" :collapse="isCollapse" ref="leftMenu">
                    <template v-for="(v, i) in entranceList">
                        <el-submenu
                                v-if="v.children.length !== 0"
                                :index="v.path"
                                :key="`system${i}`"
                        >
                            <template slot="title">
                                <i :class="$route.name.includes(v.path) ? `${v.icon}-current` : v.icon"></i>
                                <span :class="$route.name.includes(v.path) ?  `active-text` : ''">{{ v.title }}</span>
                            </template>
                            <el-menu-item
                                    v-for="(a, n) in v.children"
                                    :index="a.path"
                                    :key="`system{i}-${n}`"
                            >
                                <span :class="$route.name.includes(v.path) ?  `active-text` : ''">{{ a.title }}</span>
                            </el-menu-item>
                        </el-submenu>
                        <el-menu-item
                                v-else
                                :index="v.path"
                                :key="`system${i}`"
                        >
                            <i :class="$route.name.includes(v.path) ? `${v.icon}-current` : v.icon"></i>
                            <span slot="title" :class="$route.name.includes(v.path) ? 'active-text' : ``">{{ v.title }}</span>
                        </el-menu-item>
                    </template>
                </el-menu>
            </div>

            <div class="content-main">
                <router-view></router-view>
            </div>
        </div>

        <el-dialog
                title="修改密码"
                custom-class="misExamDialog passwDialog"
                :visible.sync="dialogPassWord"
        >
            <el-form :model="form" label-position="left" :rules="rules" ref="ruleForm">
                <el-form-item label="手机号" style="margin-top: 20px" label-width="80px">
                    <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="验证码" style="margin-top: 20px" label-width="80px">
                    <el-input
                            v-model="form.vcode"
                            placeholder="请输入验证码"
                            style="width: calc(100% - 145px);margin-right: 5px"
                    ></el-input>
                    <el-button type="primary" style="width: 140px" :disabled="waittime <= 60" @click="getForgetPwdCode">{{codebtnword}}</el-button>
                </el-form-item>
                <el-form-item label="新密码" prop="password" style="margin-top: 20px" label-width="80px" v-if="checkSuccess">
                    <el-input
                            v-model="form.password"
                            type="password"
                            show-password
                            placeholder="请输入新密码"
                    ></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="password2" style="margin-top: 20px" label-width="80px" v-if="checkSuccess">
                    <el-input
                            v-model="form.password2"
                            type="password"
                            show-password
                            placeholder="再次输入新密码"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="checkForget" v-if="!checkSuccess">验证码检验</el-button>
                <el-button type="primary" @click="changePWD" v-if="checkSuccess">确认修改</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {getForgetPwdSms,checkForgetPwd,changePwd} from "@/axios/index"
    export default {
        name: "Main",
        data() {
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.form.password) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                navCurrent: "Home",
                nickName:"",
                isCollapse: false ,
                entranceList: [
                    {title: "互动作文",path: "Home",icon: "icon-home",children:[]},
                    {title: "学生",path: "StudentInfo",icon: "icon-studentInfo",children:[]},
                    /*{title: "个人",path: "Person",icon: "icon-person",children:[]},*/
                ],
                dialogPassWord:false,
                form: {
                    phone: "",
                    vcode: "",
                    password: "",
                    password2: "",
                },
                rules: {
                    password2: [
                        { validator: validatePass2, trigger: 'blur' }
                    ]
                },
                codebtnword: '获取验证码', // 获取验证码按钮文字
                waittime:61, // 获取验证码按钮失效时间
                checkSuccess:false,//验证码检验是否通过
                id:"",
            }
        },
        created() {
            if (this.$route.name !== undefined) {
                this.navCurrent = this.$route.name
            }
        },
        methods: {
            handleCommand(command){
                if (command == 1) {
                    this.dialogPassWord = true
                    //this.form.account = sessionStorage.getItem("username")
                } else {
                    this.goLogout()
                }
            },

            getForgetPwdCode(){
                let pattern = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
                if(!pattern.test(this.form.phone)){
                    this.$message({ type: 'warning', message: '手机号格式不正确' });
                    return
                }
                getForgetPwdSms({phone:this.form.phone}).then((res)=>{
                    if(res.data.status === 0){
                        this.$message({ type: 'success', message: '验证码已发送，请稍后' });
                    }
                })
                // 因为下面用到了定时器，需要保存this指向
                let that = this
                that.waittime--
                this.codebtnword = `${this.waittime}s 后重新获取`
                let timer = setInterval(function(){
                    if(that.waittime>1){
                        that.waittime--
                        that.codebtnword = `${that.waittime}s 后重新获取`
                    }else{
                        clearInterval(timer)
                        that.codebtnword = '获取验证码'
                        that.waittime = 61
                    }
                },1000)
            },

            checkForget(){
                let params = {
                    phone : this.form.phone,
                    vcode : this.form.vcode
                };
                checkForgetPwd(params).then((res)=>{
                    if(res.data.status === 0){
                        this.$message({ type: 'success', message: '验证成功，输入新密码' });
                        this.checkSuccess = true;
                        this.id = res.data.data;
                    }
                })
            },

            changePWD(){
                let params = {
                    id : this.id,
                    pwd : this.form.password
                };
                changePwd(params).then((res)=>{
                    if(res.data.status === 0){
                        this.$message({ type: 'success', message: '修改成功，请重新登录' });
                        this.$router.replace({
                            path:'/login'
                        })
                    }
                })
            },

            goLogout(){
                sessionStorage.clear()
                this.$router.push({
                    path: "/login",
                })
            }
        },
    }
</script>

<style lang="less" scoped>
    .top-bar {
        height: 60px;
        display: flex;
        /*background-color: #6F49FA;*/
        justify-content: flex-start;
        .logo {
            width: 66px;
            background: linear-gradient(0deg, #E9ECFF 0%, #F3F5FE 100%);;
            padding: 0 13px;
            box-sizing: border-box;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: all ease-in 0.1s;
        }
        .top-bar-right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px;
            width: calc(100% - 67px);
            background: linear-gradient(0deg, #E9ECFF 0%, #F3F5FE 100%);
            transition: all ease-in 0.1s;
            .toplogo{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 22px;
                color: #111111;
                img{margin-right: 10px}
            }
            .top-bar-right-action {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .schoolName{
                    margin-right: 20px;
                }
            }
            img.my-icon {
                width: 30px;
                height: 30px;
                //margin-right: 10px;
            }
            .drop-down-personal {
                color: #ffffff;
                cursor: pointer;
            }
        }
    }
    .main-block {
        height: calc(100vh - 60px);
        .nav-main {
            float: left;
            width: 65px;
            border: none;
            background: #F9F9FE;
            height: 100%;
            transition: all ease-in 0.1s;
            .el-menu {
                background: #F9F9FE;
            }
            .el-menu-item {
                color: #94a9a3;
                background: #F9F9FE;
                &.active {
                    color: #fff;
                    background-color: #F9F9FE;
                    i {
                        margin-left: -2px;
                        color: #fff;
                    }
                }
            }
            /*box-shadow: 2px 0 3px 0 rgba(0, 0, 0, 0.09);*/
            .nav-title {
                color: #5c6166;
                padding-top: 20px;
                padding-left: 26px;
                padding-bottom: 10px;
            }
            .el-menu-item,
            /deep/ .el-submenu__title {
                height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                line-height: 1em;
                font-size: 12px;
                align-items: center;
                color: #858EAB;
                background: #F9F9FE;
                padding: 40px 10px 0!important;
            }
            .el-menu-item:focus,
            .el-menu-item:hover {
                //background-color: #394452;
            }
            /deep/ .el-submenu__title:focus,
            /deep/ .el-submenu__title:hover {
                //background-color: #394452;
            }
            .el-submenu {
                &.active{
                    /deep/ .el-submenu__title{
                        //background-color: #394452;
                    }
                }
                .el-menu-item {
                    padding-left: 62px !important;
                }
            }
            i {
                width: 30px;
                 height:30px;
                margin-bottom: 12px;
                 background-size: 100%;
                 display: inline-block;
            }
            .active-text{
                color: #111;
            }
             .is-active {
                 position: relative;
                 &::after{
                     display: block;
                     content: "";
                     position: absolute;
                     right: 0;
                     top: 48px;
                     width: 5px;
                     height: 20px;
                     background: #6F49FA;
                     border-radius: 2px;
                 }
                 i {

                 }
             }
            .is-opened {
                border-left: none;
                background-color: #ffffff;
                i {
                    margin-left: 0;
                }
                .is-active {
                    span {
                        margin-left: -2px;
                    }
                }
            }
            .icon-home {
                background-image: url("../assets/images/main/互动作文-默认.png");
            }
            .icon-studentInfo {
                background-image: url("../assets/images/main/学生管理-默认.png");
            }
            .icon-person {
                background-image: url("../assets/images/main/个人信息-默认.png");
            }
            .icon-home-current {
                background-image: url("../assets/images/main/互动作文-选中.png");
            }
            .icon-studentInfo-current {
                background-image: url("../assets/images/main/学生管理-选中.png");
            }
            .icon-person-current {
                background-image: url("../assets/images/main/个人信息-选中.png");
            }
        }
        .content-main {
            float: right;
            //padding: 0 22px;
            box-sizing: border-box;
            width: calc(100% - 65px);
            height: 100%;
            overflow-y: auto;
            transition: all ease-in 0.1s;
            /deep/ .el-breadcrumb {
                padding: 17px 0;
            }
            /deep/ .el-breadcrumb__inner.is-link {
                cursor: pointer;
                //color: #6F49FA;
            }
            /deep/ .block-bg {
                background-color: #ffffff;
                .title {
                    height: 40px;
                    display: flex;
                    font-size: 16px;
                    padding: 0 20px;
                    align-items: center;
                }
            }
            /deep/ .line {
                height: 1px;
                background-color: #e2e2e2;
            }
            /deep/ .btn-dark {
                border: none;
                color: #ffffff;
                font-size: 14px;
                background-color: #6F49FA;
            }
            /deep/ .btn-dark:hover,
           /* /deep/ .btn-dark:focus {
                background-color: rgba(10, 200, 148, 0.8);
            }*/
            /deep/ .btn-light {
                color: #8d9399;
                font-size: 14px;
                padding: 8px 14px;
                border: solid 1px #8d9399;
            }
            /deep/ .btn-light:hover,
            /*/deep/ .btn-light:focus {
                color: #6F49FA;
                border-color: rgba(10, 200, 148, 0.3);
                background-color: rgba(10, 200, 148, 0.1);
            }*/
            /deep/ .btn-plain {
                color: #6F49FA;
                font-size: 14px;
                padding: 8px 14px;
                border-color: #6F49FA;
            }
            /deep/ .btn-plain:hover,
            /deep/ .btn-plain:focus {
                color: #ffffff;
                background-color: #6F49FA;
            }
            /deep/ .el-input__inner:focus {
                border-color: #6F49FA !important;
            }
            /deep/ .el-pagination {
                padding: 0;
            }
            /deep/ .el-dialog__header {
                padding: 25px 30px;
                .custom-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    strong {
                        font-size: 16px;
                    }
                    .icon-shut {
                        width: 25px;
                        height: 25px;
                        background-size: 100%;
                        //background-image: url("../assets/images/icon-shut.png");
                    }
                }
                .el-dialog__headerbtn {
                    display: none;
                }
            }
            /deep/ .el-dialog__body {
                padding: 0;
                .el-form {
                    padding: 20px;
                    .el-select {
                        width: 100%;
                    }
                }
            }
            /deep/ .el-dialog__footer {
                padding: 15px 20px;
            }
            /deep/ .is-checked .el-radio__inner {
                border-color: #6F49FA;
                background-color: #6F49FA;
            }
            /deep/ .is-checked + .el-radio__label {
                color: #6F49FA;
            }
            /deep/ .el-input__inner {
                /* padding: 0 10px;*/
            }
            /deep/ .el-checkbox__inner:hover {
                border-color: #6F49FA;
            }
            /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
            /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                border-color: #6F49FA;
                background-color: #6F49FA;
            }
            /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
                color: #6F49FA;
            }
            /deep/ .el-pager li.active {
                color: #6F49FA;
            }
            /deep/ .el-select .el-input.is-focus .el-input__inner,
            /deep/ .el-pagination__sizes .el-input .el-input__inner:hover {
                border-color: #6F49FA;
            }
            /deep/ .el-pager li:hover {
                color: #6F49FA;
            }
            /deep/ .icon-btn {
                span {
                    display: flex;
                    align-items: center;
                }
            }
            /deep/ .icon {
                width: 15px;
                height: 15px;
                margin-right: 5px;
                background-size: 100%;
            }
            }
        }
</style>
<style>
    .el-menu {
        border-right: none !important;
    }
</style>