/*let baseURL = "http://192.168.0.104:9099",
	wxURL = "https://test-zxzw-web.yyetes.com";*/
//测试，直接提交git
let baseURL = 'https://zxzw-api.yyetes.com',
	wxURL = "https://zxzw-web.yyetes.com";
// 线上，需要打包发给后台  http://10.21.44.3:9099   https://zxzw-api.yyetes.com
export default {
	baseURL,
	wxURL
}
