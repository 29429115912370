import Axios from "axios"
import Router from "../router"
import {Message,Loading} from "element-ui"
import plugins from '../plugins/plugins'

let loading = {};
Axios.interceptors.request.use(
	(cof) => {
		loading = Loading.service({
			lock: true,
			text: '加载中',
			spinner: 'el-icon-loading',
			customClass:'loading',
			background: 'rgba(0, 0, 0, 0.1)'
		});
		return cof
	},
	(err) => {
		loading.close();
		Promise.error(err)
	}
)

Axios.interceptors.response.use(
	(res) => {
		loading.close();
		if (res.data.status && res.data.status !== 0){
			Message.error(res.data.msg)
		}
		if (res.data.status === 10006) {
			//sessionStorage.removeItem("userToken")
			//Message.error(res.data.msg)
			/*Router.replace({
				path: "/login",
			}).then((r) => {
				console.log(r)
			})*/
		} /*else if (res.data.status === 9999) {
		//} else if (res.data.status !== 0 && res.data.status !== 10019) {
			Message.error(res.data.msg)
		}*/
		return Promise.resolve(res)
	},
	(err) => {
		loading.close();
		return Promise.reject(err)
	}
)

const pacAxios = function (method, url, parameter = {},responseType) {
	return Axios({
		method: method,
		url: `${plugins.baseURL}${url}`,
		timeout: 3600000,
		headers: {
			token: sessionStorage.getItem("userToken"),
			loginPlatform:2,
			appVersion: 300
		},
		...parameter,
		responseType:responseType
	})
}

export default pacAxios
