import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import './theme/index.css'
import ElementUI from "element-ui"
import "./assets/css/common.less"

Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
