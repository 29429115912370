import Vue from "vue"
import VueRouter from "vue-router"
import Main from "../views/Main.vue"
//import {Message} from "element-ui"

Vue.use(VueRouter)
const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/login.vue"),
        meta:{title:'叮叮家校互动'}
    },
    {
        path: "/",
        component: Main,
        meta: {title: '叮叮家校互动'},
        children: [
            {
                path: "Home",
                name: "Home",
                component: () => import("../views/HOME"),
            },
            {
                path: "taskList",
                name: "Home-taskList",
                component: () => import("../views/HOME/taskList"),
            },
            {
                path: "taskDetail",
                name: "Home-taskDetail",
                component: () => import("../views/HOME/taskDetail"),
            },
            {
                path: "taskReport",
                name: "Home-taskReport",
                component: () => import("../views/HOME/taskReport"),
            },
            {
                path: "StudentInfo",
                name: "StudentInfo",
                component: () => import("../views/StudentInfo"),
            },
            {
                path: "Person",
                name: "Person",
                component: () => import("../views/Person"),
            },
        ]
    }
]

const router = new VueRouter({
    routes,
})
router.beforeEach((to, _, next) => {
    document.title =  to.matched[0].meta.title;
    if (to.fullPath.toLowerCase() !== "/login") {
        if (window.sessionStorage.getItem("userToken") !== null) {
            next()
        } else {
            next({path: "/login"})
        }
    } else {
        next()
    }
})

export default router
