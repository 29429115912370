import pacAxios from "./config"

// 用户登录
export const userLogin = (parameter) => {
    return pacAxios( "get","/app/start/login", {params: parameter})
}
//标头统计
export const getTeamStatistic = (parameter) => {
    return pacAxios( "get","/app/team/teamStatistic", {params: parameter})
}
//我的小组列表
export const getTeamList = (parameter) => {
    return pacAxios( "get","/app/team/list", {params: parameter})
}
//小组年级列表
export const getGradesList = (parameter) => {
    return pacAxios( "get","/app/judge/grades", {params: parameter})
}
//创建小组
export const createTeam = (parameter) => {
    return pacAxios( "post","/app/team/create", {data: parameter})
}
//更新小组
export const updateTeam = (parameter) => {
    return pacAxios( "post","/app/team/update", {data: parameter})
}
//小组任务列表
export const getWorkList = (parameter) => {
    return pacAxios( "get","/app/team/work/list", {params: parameter})
}
//小组任务个数
export const getWorkCount = (parameter) => {
    return pacAxios( "get","/app/team/work/count", {params: parameter})
}
//中文文体
export const getChineseThemeList = (parameter) => {
    return pacAxios( "get","/app/judge/chinese/themes", {params: parameter})
}
//同步教材-年级
export const getSyncBookGradeList = (parameter) => {
    return pacAxios( "get","/app/syncbook/grade/list", {params: parameter})
}
//同步教材-上下册
export const getSyncBookVolumeList = (parameter) => {
    return pacAxios( "get","/app/syncbook/volume/list", {params: parameter})
}
//同步教材-单元题目
export const getSyncBookQuestionList = (parameter) => {
    return pacAxios( "get","/app/syncbook/question/list", {params: parameter})
}
//小组发布任务
export const createTeamWork = (parameter) => {
    return pacAxios( "post","/app/team/work/create", {data: parameter})
}
//作业获取详情（编辑和详情使用）
export const getTeamWork = (parameter) => {
    return pacAxios( "get","/app/team/work/get", {params: parameter})
}
//小组成员列表
export const getTeamUserList = (parameter) => {
    return pacAxios( "get","/app/team/users", {params: parameter})
}
//更新权限（转让小组、设置管理员\组员）
export const updateRole = (parameter) => {
    return pacAxios( "get","/app/team/user/updateRole", {params: parameter})
}
//小组踢出成员
export const removeTeamUser = (parameter) => {
    return pacAxios( "get","/app/team/user/remove", {params: parameter})
}
//成员记录
export const getTeamUserRecords = (parameter) => {
    return pacAxios( "get","/app/team/user/records", {params: parameter})
}
//小组删除作业
export const deleteWork = (parameter) => {
    return pacAxios( "get","/app/team/work/delete", {params: parameter})
}
//解散小组
export const dismissTeam = (parameter) => {
    return pacAxios( "get","/app/team/dismiss", {params: parameter})
}
//任务上传列表
export const getUploadList = (parameter) => {
    return pacAxios( "get","/app/team/work/upload/list", {params: parameter})
}
//老师批阅
export const reviewWork = (parameter) => {
    return pacAxios( "post","/app/team/work/upload/review", {data: parameter})
}
//获取作业详情
export const getWorkResult = (parameter) => {
    return pacAxios( "get","/app/team/work/upload/result", {params: parameter})
}
//中文ocr
export const chineseOcr = (parameter) => {
    return pacAxios("post", "/app/judge/chinese/rejudge/ocr", {data: parameter})
}
//中文判评-有道
export const chineseAssess_yd = (parameter) => {
    return pacAxios("post", "/app/judge/chinese/rejudge/assess/yd", {data: parameter})
}
//中文判评-同步
export const chineseAssessSync = (parameter) => {
    return pacAxios("post", "/app/judge/chinese/rejudge/assess/sync", {data: parameter})
}
//改分
export const updateScore = (parameter) => {
    return pacAxios( "get","/app/team/work/upload/updateScore", {params: parameter})
}
//小组任务报告
export const getTeamWorkReport = (parameter) => {
    return pacAxios( "get","/app/team/work/report", {params: parameter})
}
//学生列表
export const getUserListByTeamId = (parameter) => {
    return pacAxios( "get","/app/team/getUserListByTeamId", {params: parameter})
}
//新增编辑学生
export const modifyTeamUser = (parameter) => {
    return pacAxios("post", "/app/team/user/modifyTeamUser", {data: parameter})
}
//移动小组
export const moveTeam = (parameter) => {
    return pacAxios("post", "/app/team/moveTeam", {data: parameter})
}
//导入
export const batchInsertAppUser = (parameter) => {
    return pacAxios("post", "/app/user/batchInsertAppUser", {data: parameter})
}
//学生列表导出
export const exportTeamUser = (parameter) => {
    return pacAxios( "get","/app/team/exportTeamUser", {params: parameter})
}
//发送忘记密码的验证码
export const getForgetPwdSms = (parameter) => {
    return pacAxios( "get","/app/start/forgetPwd/sms", {params: parameter})
}
//验证忘记密码
export const checkForgetPwd = (parameter) => {
    return pacAxios( "get","/app/start/forgetPwd/check", {params: parameter})
}
//修改密码
export const changePwd = (parameter) => {
    return pacAxios( "get","/app/start/forgetPwd/change", {params: parameter})
}